/* globals charts */
import '../styles/app.scss';

const dc = require('dc');
const d3 = require('d3');
const crossfilter = require('crossfilter2').default;

window.charts = {
  datasetSize: dc.dataCount('#dataset-size'), // Text - e.g. 1500 vehicles
  fleetSize: dc.dataCount('#fleet-size'), // Text - e.g. 1,000 vehicles
  // averageFleetAge: dc.numberDisplay('#average-fleet-age'), // Text - e.g. 3 years
  adoption: dc.lineChart('#adoption'), // Line chart - volume over time
  motivePower: dc.pieChart('#motive-power'), // Pie chart
  originalCountry: dc.pieChart('#original-country'), // Pie chart or table
  model: dc.dataTable('#model-table'), // - Data table? Probably too many results for a pie
  fleetAge: dc.barChart('#fleet-age'), // Bar - number of fleet ages
  colour: dc.dataTable('#colour-table'), // Pie chart or data table,
  tlaPopulation: dc.dataTable('#tla-table'), // Region with highest count of EVs
  reset: () => { dc.filterAll(); dc.redrawAll(); },
};

Promise.all([d3.csv('/register_evs.csv')]).then(([vehicles]) => {
  vehicles.forEach((v) => {
    // eslint-disable-next-line no-param-reassign
    v.makeModel = `${v.MAKE} ${v.MODEL}`;
    // eslint-disable-next-line no-param-reassign
    v.age = new Date().getFullYear() - parseInt(v.VEHICLE_YEAR, 10);
  });

  const ndx = crossfilter(vehicles);

  const popularModels = ndx
    .dimension((data) => data.makeModel)
    .group()
    .reduceCount();

  const popularColours = ndx
    .dimension((data) => data.BASIC_COLOUR)
    .group()
    .reduceCount();

  const fleetAge = ndx.dimension((data) => data.age);

  /* eslint-disable no-param-reassign */
  const averageFleetAge = ndx.groupAll().reduce(
    (prev, current) => {
      prev.count += 1;
      prev.sum += current.age;
      return prev;
    },
    (prev, current) => {
      prev.count -= 1;
      prev.sum -= current.age;
      return prev;
    },
    () => ({
      count: 0,
      sum: 0,
      average() {
        if (this.count === 0) return 0;
        return this.sum / this.count;
      },
    }),
  );
  /* eslint-enable no-param-reassign */

  const registrationYearDimension = ndx.dimension(
    (data) => data.FIRST_NZ_REGISTRATION_YEAR,
  );

  const motivePowerDimension = ndx.dimension((data) => data.MOTIVE_POWER);
  const tlaPopulation = ndx.dimension((data) => data.TLA).group().reduceCount();
  const originalCountryDimension = ndx.dimension((data) => data.ORIGINAL_COUNTRY)
    .group().reduceCount();

  const updateSummaries = () => {
    document.querySelector(
      '#most-popular-model-name',
    ).innerText = popularModels.top(1)[0].key;

    document.querySelector('#original-country-top-name')
      .innerText = originalCountryDimension.top(1)[0].key;


    const originalCountryCount = originalCountryDimension.top(1)[0].value;
    const datasetSize = ndx.allFiltered().length;
    const originalCountryPercentage = (originalCountryCount / datasetSize) * 100;
    document.querySelector('#original-country-top-percentage')
      .innerText = `${Math.round(originalCountryPercentage)}%`;

    document.querySelector(
      '#average-fleet-age',
    ).innerText = `${averageFleetAge.value().average().toFixed(1)} years`;

    document.querySelector(
      '#most-popular-colour-name',
    ).innerText = popularColours.top(1)[0].key;

    document.querySelector(
      '#ev-population-winner-name',
    ).innerText = tlaPopulation.top(1)[0].key;

    document.querySelector(
      '#ev-population-winner-count',
    ).innerText = tlaPopulation.top(1)[0].value;

    document.querySelector(
      '#oldest-registration-year',
    ).innerText = registrationYearDimension.bottom(1)[0].FIRST_NZ_REGISTRATION_YEAR;

    document.querySelector(
      '#oldest-vehicle-name',
    ).innerText = fleetAge.top(1)[0].makeModel;

    document.querySelector(
      '#oldest-vehicle-year',
    ).innerText = fleetAge.top(1)[0].VEHICLE_YEAR;

    const bevFilter = motivePowerDimension.currentFilter;
    const bevCount = motivePowerDimension
      .group()
      .reduceCount()
      .all()
      .find(({ key }) => key === "ELECTRIC")
      .value;
    
    document.querySelector("#motive-power-bev-percentage")
      .innerText = `${Math.round((bevCount / (bevFilter ? datasetSize : ndx.size)) * 100)}%`;
  };


  charts.datasetSize.crossfilter(ndx).groupAll(ndx.groupAll());

  charts.fleetSize.crossfilter(ndx).groupAll(ndx.groupAll());

  charts.adoption
    .controlsUseVisibility(true)
    .width(null)
    .margins({
      top: 10, right: 50, bottom: 30, left: 100,
    })
    .height(640)
    .dimension(registrationYearDimension)
    .group(registrationYearDimension.group().reduceCount())
    .elasticY(true)
    .mouseZoomable(false)
    .curve(d3.curveLinear)
    .x(d3.scaleLinear().domain([2000, 2019]).nice().interpolate(d3.interpolateRound))
    .xAxisLabel('Year')
    .yAxisLabel('Annual Registrations')
    .xAxis()
    .tickFormat((v) => v);
  charts.adoption.title("EV Adoption by Registrations")

  charts.fleetAge
    .controlsUseVisibility(true)
    .width(null)
    .margins({
      top: 10, right: 50, bottom: 30, left: 100,
    })
    .height(640)
    .dimension(fleetAge)
    .elasticY(true)
    .group(fleetAge.group().reduceCount())
    .brushOn(false)
    .x(d3.scaleLinear().domain([0, 30]))
    .xAxisLabel('Vehicle Age (Years)')
    .yAxisLabel('Number of Vehicles')
    .xAxis()
    .tickFormat((v) => v);

  charts.fleetSize
    .on('postRender', updateSummaries)
    .on('postRedraw', updateSummaries);

  charts.model
    .dimension(popularModels)
    .showSections(false)
    .columns([(d) => d.key, (d) => d.value])
    .order(d3.descending)
    .size(10)
    .width(null);

  charts.colour
    .dimension(popularColours)
    .showSections(false)
    .columns([(d) => d.key, (d) => d.value])
    .order(d3.descending)
    .width(null);

  charts.tlaPopulation
    .dimension(tlaPopulation)
    .showSections(false)
    .columns([(d) => d.key, (d) => d.value])
    .order(d3.descending)
    .width(null);

  charts.motivePower
    .width(null)
    .height(480)
    .slicesCap(5)
    .innerRadius(100)
    .dimension(motivePowerDimension)
    .renderLabel(true)
    .minAngleForLabel(0.1)
    .externalLabels(50)
    .externalRadiusPadding(70)
    .ordinalColors(["#C8E6C9", "#9DC6B4", "#79A6A0", "#5A868A", "#426672", "#2F4858"])
    .group(motivePowerDimension.group().reduceCount());

  charts.originalCountry
    .width(null)
    .height(480)
    .slicesCap(5)
    .innerRadius(100)
    .dimension(originalCountryDimension)
    .group(originalCountryDimension)
    .renderLabel(true)
    .innerRadius(100)
    .ordinalColors(["#C8E6C9", "#9DC6B4", "#79A6A0", "#5A868A", "#426672", "#2F4858"])
    .minAngleForLabel(0);

  dc.renderAll();
});

// document.querySelector("#reset").addEventListener("click", () => {
//   dc.filterAll();
//   dc.renderAll();
// });
